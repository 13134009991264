import React from 'react'
import FormButton from '../shared/FormButton'

const ControlOptions = ({
  loading,
  handleConfirmCreateBills,
  handleGetBillData,
  setShowModal,
  setModalType
}) => (
  <div className="mx-10 mb-2 flex items-start">
    <form className="flex flex-col items-start gap-4">
      <div className="flex h-12 items-center gap-4">
        <FormButton
          isEditing={loading}
          text="Buscar pedidos"
          onClick={() => {
            setShowModal(true)
            setModalType('fetch')
          }}
          type="button"
          styles="px-4 h-10 mt-5 text-base"
        />
        <FormButton
          isEditing={loading}
          text="Crear Facturas"
          onClick={handleConfirmCreateBills}
          type="button"
          styles="px-4 h-10 mt-5 text-base"
        />
        <FormButton
          isEditing={loading}
          text="Detalle General"
          onClick={handleGetBillData}
          type="button"
          styles="px-4 h-10 mt-5 text-base"
        />
      </div>
    </form>
  </div>
)

export default ControlOptions
