import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { BILL_FILE_TYPES, PAYMENT_STATUSES } from '../../constants/billing'
import { getBills, removeFile, updateBills, uploadFile } from '../../helpers/requests/billing'
import Loader from '../../components/shared/Loader'
import { BILLING_ICONS, errorIcon, infoIcon, prevIcon } from '../../constants/icons'
import FileUpload from '../../components/Details/HoldingBilling/FileUpload'
import FormButton from '../../components/shared/FormButton'
import ConfirmModal from '../../components/shared/Modal/ConfirmModal'

const EditBill = () => {
  const [bill, setBill] = useState({})
  const [formValues, setFormValues] = useState({})
  const [apiResponse, setApiResponse] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [invoiceFile, setInvoiceFile] = useState(null)
  const [receiptFile, setReceiptFile] = useState(null)
  const [creditNoteFile, setCreditNoteFile] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [currentFile, setCurrentFile] = useState({})

  const invoiceRef = useRef(null)
  const receiptRef = useRef(null)
  const creditNoteRef = useRef(null)

  const fileRefs = {
    invoice: invoiceRef,
    receipt: receiptRef,
    credit_note: creditNoteRef
  }

  const fileStates = {
    invoice: invoiceFile,
    receipt: receiptFile,
    credit_note: creditNoteFile
  }

  const history = useHistory()
  const { id: holdingId, billId } = useParams()

  const initialzeData = useCallback((data) => {
    setBill(data)
  }, [])

  useEffect(() => {
    if (shouldFetch) {
      getBills(holdingId, billId)
        .then(initialzeData)
        .finally(() => setShouldFetch(false))
    }
  }, [shouldFetch, billId, initialzeData, holdingId])

  useEffect(() => {
    setFormValues({ ...bill })
  }, [bill])

  const billingFileField = {
    invoice: setInvoiceFile,
    receipt: setReceiptFile,
    credit_note: setCreditNoteFile
  }

  const handleGoBack = () => {
    history.push(`/details/${holdingId}`)
  }

  const handleFiles = (files, name) => {
    const formData = new FormData()
    for (let i = 0; i < files.length; i += 1) {
      formData.append('file', files[i])
    }
    const setFileFunction = billingFileField[name]
    setFileFunction(formData)
  }

  const handleChange = (event) => {
    const { name, value, files } = event.target

    if (files) {
      handleFiles(files, name)
    } else {
      setFormValues({
        ...formValues,
        [name]: value
      })
    }
  }

  const handleSubmitModal = async () => {
    setIsLoading(true)
    const { data, status } = await updateBills({ bill: formValues })
    setApiResponse({ ...data, status })
    setShouldFetch(true)
    setIsLoading(false)
  }

  const removeLocalFile = (id) => {
    if (id.includes('receipt')) {
      setReceiptFile(null)
      receiptRef.current.value = null
    } else if (id.includes('invoice')) {
      setInvoiceFile(null)
      invoiceRef.current.value = null
    } else if (id.includes('credit')) {
      setCreditNoteFile(null)
      creditNoteRef.current.value = null
    }
  }

  const removeAwsFile = async (file, id) => {
    await removeFile(file.map(obj => obj.name).join(','))
    let updatedFormValues
    if (id.includes('receipt')) updatedFormValues = { ...formValues, receipt: null }
    if (id.includes('invoice')) updatedFormValues = { ...formValues, invoice: null }
    if (id.includes('credit')) updatedFormValues = { ...formValues, credit_note: null }
    setFormValues(updatedFormValues)
    return updateBills({ bill: updatedFormValues })
  }

  const handleUploadFile = async (type) => {
    setIsLoading(true)
    const fileUrls = {}
    const { data: fileResponse } = await uploadFile(fileStates[type])
    fileUrls[type] = fileResponse.filesData
    const { data, status } = await updateBills({
      bill: { ...formValues, ...fileUrls }
    })
    removeLocalFile(type)
    setIsLoading(false)
    setApiResponse({ ...data, status })
    setShouldFetch(true)
  }

  const handleFileDelete = async () => {
    setIsLoading(true)
    const { data, status } = await removeAwsFile(currentFile.file, currentFile.id)
    setApiResponse({ ...data, status })
    setShouldFetch(true)
    setIsLoading(false)
    setShowModal(false)
  }

  const handleFileDeleteModal = async (file, id, type) => {
    if (type === 'local') {
      removeLocalFile(id)
      return
    }
    setShowModal(true)
    setCurrentFile({ id, file, type })
  }

  const handleButtonClick = (type) => () => {
    if (type === 'invoice') invoiceRef.current.click()
    if (type === 'receipt') receiptRef.current.click()
  }

  const debtStatusColor = (status) => {
    let bgColor = ''
    if (status === 'paid') bgColor = 'border-green'
    else if (status === 'debt') bgColor = 'border-yellow'
    else if (status === 'expired') bgColor = 'border-red'
    return bgColor
  }

  const renderIcon = () => {
    if (isLoading) return <Loader />

    const icon = apiResponse?.status === 200 ? BILLING_ICONS.paid : errorIcon
    const altText = apiResponse?.status === 200 ? 'success' : 'error'
    if (apiResponse) {
      return (
        <div className="flex flex-col items-center justify-center">
          <img src={icon} className="mx-auto h-16 w-16" alt={altText} />
          {apiResponse?.status === 200
            ? 'Cambios guardados exitosamente'
            : `Ha ocurrido un error al guardar los datos: ${apiResponse?.body}`}
        </div>
      )
    }
    return <img src={infoIcon} className="h-auto w-16 opacity-20" alt="Info" />
  }

  const compareEditedData = () => JSON.stringify(formValues) === JSON.stringify(bill)
  const restoreData = () => setFormValues({ ...bill })

  return (
    <div className="relative flex h-fit">
      <div className="m-10 h-fit w-1/2">
        <ConfirmModal
          show={showModal}
          handleSubmit={handleFileDelete}
          handleClose={() => setShowModal(false)}
          isLoading={isLoading}
          message="Borrar? Esta acción no se puede deshacer"
        />
        <div className="mb-8">
          <div className="text-left text-xl font-semibold">{`Editar Factura - ${
            bill['Holding.name'] || ''
          } ${bill.month || ''}/${bill.year || ''}`}</div>
          <button
            type="button"
            className="mt-4 flex items-center justify-center rounded-lg px-2 py-1 hover:bg-medium-gray"
            onClick={() => handleGoBack()}
          >
            <img src={prevIcon} className="h-auto w-5" alt="volver" /> Volver
          </button>
        </div>
        <div className="flex flex-col gap-8">
          <div>
            Folio
            <input
              id="folio"
              type="text"
              name="invoice_number"
              className="h-10 w-full rounded-lg border-2 border-dark-grey bg-white px-2"
              value={formValues?.invoice_number || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex w-full gap-2">
            <div className="w-1/2">
              <div>Emisión</div>
              <input
                id="emitModal"
                name="created_at"
                className="h-10 w-full rounded-lg border-2 border-dark-grey bg-white px-2"
                type="date"
                value={formValues?.created_at || ''}
                onChange={handleChange}
              />
            </div>
            <div className="w-1/2">
              <div>Expiración</div>
              <input
                id="expireDateModal"
                name="expires"
                className="h-10 w-full rounded-lg border-2 border-dark-grey bg-white px-2"
                type="date"
                value={formValues?.expires || ''}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            A cobrar
            <input
              id="pinflag_charge"
              type="number"
              name="pinflag_charge"
              className="h-10 w-full rounded-lg border-2 border-dark-grey bg-white px-2"
              value={formValues?.pinflag_charge || 0}
              onChange={handleChange}
            />
          </div>
          {BILL_FILE_TYPES.map((billType) => (
            <FileUpload
              key={billType.type}
              label={billType.label}
              subText="PDF"
              inputId={`${billType.type}`}
              inputRef={fileRefs[billType.type]}
              file={fileStates[billType.type]}
              fetchedFiles={bill[billType.type]}
              handleChange={handleChange}
              handleButtonClick={handleButtonClick(billType.type)}
              handleFileDelete={handleFileDeleteModal}
              handleFileUpload={handleUploadFile}
              properties={{ multiple: billType.multiple, name: billType.type }}
            />
          ))}
          <div className="flex gap-2">
            {PAYMENT_STATUSES?.map((status) => (
              <button
                type="button"
                className={`w-20 rounded-lg border-2 bg-white drop-shadow-md hover:bg-medium-gray ${
                  formValues.status === status.name ? `${debtStatusColor(status.name)}` : ''
                }`}
                key={status.name}
                onClick={() => handleChange({ target: { value: status.name, name: 'status' } })}
              >
                {status.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="grid w-1/2 grid-rows-2">
        <div className="flex items-end justify-center">{renderIcon()}</div>

        <div className="flex items-end justify-end gap-4 p-4">
          {!compareEditedData() && (
            <>
              <FormButton text="Cancelar" onClick={restoreData} styles="bg-white px-4 py-2" />
              <FormButton text="Guardar" onClick={handleSubmitModal} styles="px-4 py-2" />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditBill
