import React from 'react'

import Modal from '../shared/Modal/Modal'
import BillingModal from './BillingModal'
import FetchDataModal from './FetchDataModal'
import FileUploadModal from './FileUploadModal'
import ConfirmModal from '../shared/Modal/ConfirmModal'

const ModalHandler = ({
  showModal,
  modalType,
  modalMessage,
  loading,
  handleCloseModal,
  saveData,
  handleSubmit
}) => (
  <Modal show={showModal} handleClose={handleCloseModal}>
    {modalType === 'response' && <BillingModal text={modalMessage} isLoading={loading} />}
    {modalType === 'fetch' && <FetchDataModal />}
    {modalType === 'upload' && <FileUploadModal saveData={saveData} isLoading={loading}/>}
    {modalType === 'confirm' && <ConfirmModal show={showModal} handleSubmit={handleSubmit} handleClose={handleCloseModal}
    isLoading={loading} message={modalMessage}/>}
  </Modal>
)

export default ModalHandler
