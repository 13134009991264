import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import HoldingBilling from '../../components/Details/HoldingBilling/HoldingBilling'
import Loader from '../../components/shared/Loader'
import HoldingDetails from '../../components/Details/HoldingDetails/HoldingDetails'

const Details = () => {
  const [isFirstView, setIsFirstView] = useState(true)
  const holding = {}

  const { id: holdingId } = useParams()

  const isLoadingHoldingData = false

  const renderViews = () => {
    if (isFirstView) {
      return !isLoadingHoldingData ? (
        <HoldingDetails holding={{ holdingId, ...holding }} />
      ) : (
        <Loader />
      )
    }
    return  (
      <HoldingBilling
        holding={{ id: holdingId, name: holding.bankData?.name || 'name' }}
      />
    )
  }

  return (
    <div className="relative flex h-screen flex-col bg-light-grey">
      <div className="absolute top-0 left-24 rounded-b-lg bg-pinflag-dark p-2 font-medium text-white">
        {holding?.bankData?.name || 'Name Not Found'}
      </div>
      <div className=" mx-10 my-5">
        <div className="flex items-center justify-center	">
          <div className="grid w-96 grid-cols-2 content-center">
            <button
              className={`${
                isFirstView ? 'border-b-2 border-current text-pinflag-dark' : 'text-medium-gray'
              }`}
              type="button"
              onClick={() => setIsFirstView(true)}
            >
              Información
            </button>
            <button
              className={`${
                !isFirstView ? 'border-b-2 border-current text-pinflag-dark' : 'text-medium-gray'
              }`}
              type="button"
              onClick={() => setIsFirstView(false)}
            >
              Facturación
            </button>
          </div>
        </div>
        {renderViews()}
      </div>
    </div>
  )
}

export default Details
