import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import Modal from '../../shared/Modal/Modal'
import InputForm from '../../shared/Input/InputForm'
import FormButton from '../../shared/FormButton'
import { GENERAL_NOTIFICATIONS_TYPES } from '../../../constants/notificationCenter'
import { updateNotifications } from '../../../helpers/requests/notificationCenter'

import HoldingList from './HoldingList'
import CustomEditor from './CustomEditor'
import { nextIcon, prevIcon } from '../../../constants/icons'

const NotificationModal = ({ notification, holdings, showModal, handleClose, shouldFetch }) => {
  const [editedNotification, setEditedNotification] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [currentView, setCurrentView] = useState('Main')
  const [apiResponse, setApiResponse] = useState()
  const [selectedHoldings, setSelectedHoldings] = useState([])
  const [currentViewIndex, setCurrentViewIndex] = useState(0)

  useEffect(() => {
    if (notification.Holdings) {
      const holdingIds = notification.Holdings.map((holding) => holding.id)
      setSelectedHoldings(holdingIds)
    }
  }, [notification.Holdings])

  const handleInputChange = (type, field, value) => {
    type !== 'Main'
      ? setEditedNotification((prevNotification) => ({
          ...prevNotification,
          [type]: {
            ...prevNotification[type],
            [field]: value
          }
        }))
      : setEditedNotification((prevNotification) => ({
          ...prevNotification,
          [field]: value
        }))
  }
  const handleViewChange = (newView) => {
    setCurrentView(newView)
    setCurrentViewIndex(newView === 'Main' ? 0 : 1)
  }

  const handleHoldingSelect = (company) => {
    setSelectedHoldings((prevSelected) =>
      prevSelected.includes(company.id)
        ? prevSelected.filter((id) => id !== company.id)
        : [...prevSelected, company.id]
    )
  }

  const handleSelectAllHoldings = (isActive, filteredHoldings) => {
    if (isActive) {
      const holdingIds = filteredHoldings.map((holding) => holding.id)
      setSelectedHoldings(holdingIds)
    } else setSelectedHoldings([])
  }

  const getNotificationData = () =>
    currentView === 'Main'
      ? {
          name: editedNotification.name,
          active: editedNotification.active,
          holdingIds: selectedHoldings
        }
      : { ...editedNotification[currentView] }

  const handleApiResponse = (response) => {
    setApiResponse(response)
  }
  const getNotificationType = () =>
    currentView === 'Main' ? null : currentView.charAt(0).toLowerCase() + currentView.slice(1)

  const prepareNotificationData = () => ({
    notificationId: editedNotification.id,
    notification: {
      type: getNotificationType(),
      notificationData: getNotificationData()
    }
  })

  const saveNotificationChanges = async () => {
    setIsLoading(true)
    const data = prepareNotificationData()

    const response = await updateNotifications(data)
    handleApiResponse(response)
    setIsLoading(false)
    shouldFetch(true)
  }

  useEffect(() => {
    if (notification) {
      setEditedNotification({ ...notification })
    }
  }, [notification])

  useEffect(() => {
    setApiResponse()
  }, [currentView])

  const renderActiveCheckbox = (type) => (
    <div className="flex items-center">
      <div className="text-base">Activo</div>
      <InputForm
        id={`${type.toLowerCase()}_${editedNotification.id}`}
        size="w-12"
        name={type.toLowerCase()}
        type="checkbox"
        value=""
        checked={
          currentView === 'Main' ? editedNotification?.active : editedNotification[type]?.active
        }
        onChange={(e) => handleInputChange(type, 'active', e.target.checked)}
      />
    </div>
  )

  const renderPreview = () => {
    switch (currentView) {
      case 'Banner':
        return (
          <div className="w-full bg-pinflag py-4 text-center font-medium text-white drop-shadow-lg">
            {parse(editedNotification.Banner?.content || '')}
          </div>
        )
      case 'PopUp':
        return (
          <div className="w-11/12 rounded-xl bg-white p-4 drop-shadow-lg">
            <div className="mb-2 flex items-center justify-end">
              <div className="text-base">x</div>
            </div>
            <div className="text-sm">{parse(editedNotification.PopUp?.content || '')}</div>
          </div>
        )
      case 'Mail':
        return (
          <div className="w-11/12 rounded-sm bg-white p-4 drop-shadow-lg">
            <div className="mb-5 font-semibold">{parse(editedNotification.Mail?.about || '')}</div>
            <div className="text-sm">{parse(editedNotification.Mail?.content || '')}</div>
          </div>
        )
      case 'Main':
        return (
          <div className="flex w-full items-center justify-center">
            <HoldingList
              holdings={holdings}
              holdingSelection={selectedHoldings}
              handleHoldingSelect={handleHoldingSelect}
              handleSelectAll={handleSelectAllHoldings}
            />
          </div>
        )
      default:
        return null
    }
  }

  const renderApiResponse = () => (
    <div
      className={`absolute top-0 text-xs ${
        apiResponse?.status === 200 ? 'text-emerald-500' : 'text-red'
      }`}
    >
      {isLoading ? 'Guardando...' : ''}
      {apiResponse?.data.body || ''}
    </div>
  )

  const renderPrimaryOptions = () => (
    <div className="mb-4 w-full flex-none pl-1">
      <InputForm
        id="main_input_name"
        type="input"
        label="Nombre de la notificación"
        colSpan="mb-4"
        size="w-3/5"
        value={editedNotification.name || ''}
        onChange={(e) => handleInputChange(currentView, 'name', e.target.value)}
      />
      {renderActiveCheckbox(currentView)}
    </div>
  )

  const renderSecondaryOptions = () => (
    <div className="w-full flex-none">
      <div className="mb-4">Contenido</div>
      <CustomEditor
        value={editedNotification[currentView]?.content || ''}
        onChange={(e) => handleInputChange(currentView, 'content', e.target.value)}
      />
      {renderActiveCheckbox(currentView)}

      <button
        className="mt-4 h-6 w-6 hover:opacity-75"
        type="button"
        onClick={() => handleViewChange('Main')}
      >
        <img className="my-0 mr-4 h-auto w-6" src={prevIcon} alt="Volver" />
      </button>
    </div>
  )

  return (
    <Modal title="Editar Notificación" show={showModal} handleClose={handleClose}>
      <div className="my-10 flex h-[70vh] overflow-hidden">
        <div className="relative mx-10 w-96">
          {renderApiResponse()}
          <div
            className="my-10 flex h-fit flex-col content-end gap-5 overflow-hidden"
            style={{
              msOverflowStyle: 'none',
              scrollbarWidth: 'none'
            }}
          >
            {currentView === 'Mail' && (
              <InputForm
                id="about_input"
                type="input"
                label="Asunto"
                size="w-full"
                value={editedNotification[currentView]?.about || ''}
                onChange={(e) => handleInputChange(currentView, 'about', e.target.value)}
              />
            )}
            <div
              className="flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${currentViewIndex * 100}%)` }}
            >
              <div className="w-full flex-none">
                {renderPrimaryOptions()}
                <div className=" z-0 grid h-full grid-rows-4">
                  {GENERAL_NOTIFICATIONS_TYPES.map(
                    (notificationType) =>
                      notificationType.name !== 'Main' && (
                        <button
                          key={notificationType.name}
                          type="button"
                          className="flex h-16 w-full items-center justify-between gap-4 rounded-lg px-4 drop-shadow-sm hover:bg-light-grey"
                          onClick={() => handleViewChange(notificationType.name)}
                        >
                          {notificationType.name}
                          <img
                            className="my-auto ml-4 h-auto w-6"
                            src={nextIcon}
                            alt="Ir a notificación"
                          />
                        </button>
                      )
                  )}
                </div>
              </div>
              {renderSecondaryOptions()}
            </div>
            <div className="z-10 -mt-2 flex w-full items-end">
              <FormButton text="Guardar" styles="w-full py-1" onClick={saveNotificationChanges} />
            </div>
          </div>
        </div>
        <div
          className={`relative mx-10 flex w-96 items-center justify-center drop-shadow-sm ${
            currentView === 'Main' ? 'bg-white' : 'bg-medium-gray'
          }`}
        >
          <div className="flex w-full select-none justify-center">{renderPreview()}</div>
          {currentView !== 'Main' && (
            <div className="absolute top-0  w-full select-none bg-white text-center">Preview</div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default NotificationModal
