import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { isSubstring } from '../../../utils/strings'
import { BILLING_STATUS_INDEX, BILL_STATUS } from '../../../constants/billing'
import Table from '../../shared/Table/Table'
import TableRow from '../../shared/Table/TableRow'
import ToolTip from '../../shared/ToolTip'
import SearchBar from '../../shared/SearchBar'
import Pagination from '../../shared/Pagination'
import ActionsBar from '../../shared/Table/ActionsBar'
import { handleDownload } from '../../../helpers/OrdersBilling'
import {
  createBills,
  getBilledPackages,
  getBillFile,
  getBills
} from '../../../helpers/requests/billing'
import { getFormattedDate } from '../../../utils/dates'
import FormButton from '../../shared/FormButton'
import useFetch from '../../../hooks/useFetch'
import Loader from '../../shared/Loader'

import ModalHandler from './ModalHandler'

const HoldingBilling = ({ holding }) => {
  const [billings, setBillings] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [error, setError] = useState(false)
  const [shouldFetch, setShouldFetch] = useState(false)
  const [toolTipBillId, setToolTipBillId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const initialzeData = useCallback((data) => {
    setBillings(data)
    setSearchResult(data)
  }, [])

  const { isLoading: isLoadingContent } = useFetch(getBills, initialzeData, holding.id)
  const tooltipRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setToolTipBillId(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (shouldFetch) {
      getBills(holding.id)
        .then(initialzeData)
        .finally(() => setShouldFetch(false))
    }
  }, [shouldFetch, initialzeData, holding.id])

  const redirectToEditPage = (billId) => {
    history.push(`${window.location.pathname}/${billId}`)
  }

  const debtStatusColor = (status) => {
    let bgColor = ''
    if (status === 'paid') bgColor = 'bg-green'
    else if (status === 'debt') bgColor = 'bg-yellow'
    else if (status === 'expired') bgColor = 'bg-red'
    return <div className={`w-1 rounded-full p-1 ${bgColor}`} />
  }

  const debtStatus = (status) => {
    if (status === 'paid') return 'Pagado'
    if (status === 'debt') return 'Deuda'
    if (status === 'expired') return 'Vencido'
    return 'No manejado'
  }
  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const filteredBills = billings.filter(
        (billing) =>
          isSubstring(billing.id.toString(), searchTerm) ||
          (billing.status && isSubstring(billing.status, searchTerm)) ||
          (billing.range && isSubstring(billing.range, searchTerm)) ||
          (billing.folio && isSubstring(billing.folio.toString(), searchTerm))
      )
      setSearchResult(filteredBills)
    } else {
      setSearchResult(billings)
    }
  }

  const stateFilteredBills =
    selectedFilters.length > 0
      ? searchResult.filter((bill) => selectedFilters.includes(BILLING_STATUS_INDEX[bill.status]))
      : searchResult

  const currentBills = stateFilteredBills?.slice(pageSize * (page - 1), pageSize * page) || []

  const downloadDetail = async (billingId) => {
    const { newPackages: holdingBillDetail } = await getBilledPackages({
      holdingId: Number(holding.id),
      billingId
    })
    handleDownload(holdingBillDetail, holding.name)
  }

  const handleCreateBills = async () => {
    setError(false)
    const response = await createBills(holding.id)
    if (response.status !== 200) setError(true)
    setShowModal(false)
    setShouldFetch(true)
    setModalType('response')
    setShowModal(true)
  }

  const handleConfirmCreateBills = (billingData) => {
    const currentDate = new Date()
    const isDuplicateBill = billingData.some(
      ({ year, month }) =>
        year === currentDate.getFullYear() && month === currentDate.getMonth() + 1
    )
    if (isDuplicateBill) {
      setModalType('confirm')
      setShowModal(true)
      return
    }
    handleCreateBills()
  }

  const handleCloseModal = () => {
    if (showModal) setShowModal(false)
  }

  const openOptions = (id) => {
    if (toolTipBillId === id) {
      setToolTipBillId(null)
    } else {
      setToolTipBillId(id)
    }
  }

  const handleGetFile = async (fileName) => {
    setModalType('url')
    setIsLoading(true)
    setShowModal(true)
    const { data } = await getBillFile(fileName)
    setIsLoading(false)
    setShowModal(false)
    window.open(data.url, '_blank')
  }

  const tableContent = () => (
    <div>
      {isLoadingContent ? (
        <Loader />
      ) : (
        <Table
          headers={[
            'Status',
            'Período',
            'Folio',
            'Emisión',
            'Vencimiento',
            'Cobrado',
            'A Cobrar',
            'Gan.Teórico',
            'Gan.Real',
            'NC',
            'Factura',
            'Detalle',
            'Comprobante',
            ''
          ]}
        >
          {currentBills?.map((billing) => (
            <TableRow
              key={billing.id}
              onClick={() => {}}
              items={[
                <div className="relative items-center">
                  <ToolTip
                    backgroundColor="bg-pinflag-dark"
                    textColor="text-white"
                    hoverElement={debtStatusColor(billing.status)}
                    up
                    right
                  >
                    <div>{debtStatus(billing.status)}</div>
                  </ToolTip>
                </div>,
                <div className="flex items-center">
                  {billing.month}-{billing.year}
                </div>,
                <div className="flex items-center">{billing.invoice_number || '-'}</div>,
                <div className="flex items-center">
                  {getFormattedDate(billing.created_at) || '-'}
                </div>,
                <div className="flex items-center">{getFormattedDate(billing.expires) || '-'}</div>,
                <div className="flex items-center">{billing.net_value || '-'}</div>,
                <div className="flex items-center">{billing.pinflag_charge || '-'}</div>,
                <div className="flex items-center">
                  {billing.base_price_total - billing.net_value || '-'}
                </div>,
                <div className="flex items-center">
                  {billing.pinflag_charge - billing.net_value || '-'}
                </div>,
                <div className="relative flex items-center">
                  {billing.credit_note ? (
                    <>
                      {toolTipBillId === billing.id && (
                        <div
                          ref={tooltipRef}
                          className="absolute left-[100%] top-[-251%] z-50 flex flex-col border-2 bg-white p-2 drop-shadow-lg "
                        >
                          {billing.credit_note?.map((file) => (
                            <button
                              key={file.name}
                              type="button"
                              onClick={() => {
                                handleGetFile(file?.name)
                              }}
                              className="w-24 text-center font-bold decoration-solid hover:bg-medium-gray"
                            >
                              {file.name.split('-')[1]}
                            </button>
                          ))}
                        </div>
                      )}
                      <button
                        type="button"
                        onClick={() => openOptions(billing.id)}
                        className="w-fit"
                      >
                        Ver
                      </button>
                    </>
                  ) : (
                    '-'
                  )}
                </div>,
                <div className="flex items-center">
                  {billing.invoice?.[0].name ? (
                    <button
                      type="button"
                      onClick={() => {
                        handleGetFile(billing.invoice?.[0].name)
                      }}
                      className="font-bold decoration-solid"
                    >
                      pdf
                    </button>
                  ) : (
                    '-'
                  )}
                </div>,
                <div className="flex items-center">
                  <button
                    type="button"
                    className="font-bold decoration-solid"
                    onClick={() => {
                      downloadDetail(billing.id)
                    }}
                  >
                    xlsx
                  </button>
                </div>,
                <div className="flex items-center">
                  {billing.receipt?.[0].name ? (
                    <button
                      type="button"
                      onClick={() => {
                        handleGetFile(billing.receipt?.[0].name)
                      }}
                      className="font-bold decoration-solid"
                    >
                      pdf
                    </button>
                  ) : (
                    '-'
                  )}
                </div>,
                <button type="button" onClick={() => redirectToEditPage(billing.id)}>
                  editar
                </button>
              ]}
            />
          ))}
        </Table>
      )}
    </div>
  )

  return (
    <div className="flex h-screen flex-col bg-light-grey">
      <div className=" my-5">
        <div className="mx-10 my-5 flex justify-end">
          <ModalHandler
            showModal={showModal}
            modalType={modalType}
            loading={isLoading}
            handleCloseModal={handleCloseModal}
            handleSubmit={handleCreateBills}
            error={error}
          />
          <FormButton
            isEditing={false}
            text="Crear Factura"
            onClick={() => {
              handleConfirmCreateBills(billings)
            }}
            type="button"
            styles="mx-2 py-2 px-2 text-base"
          />
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        </div>
        <ActionsBar
          statusFilters={BILL_STATUS}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
        {tableContent()}
      </div>
      <div className="mx-10 flex h-24 place-content-end">
        {stateFilteredBills.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={stateFilteredBills.length}
          />
        )}
      </div>
    </div>
  )
}

export default HoldingBilling
