import React from 'react'
import Modal from '../../shared/Modal/Modal'
import PageLoaderModal from './PageLoaderModal'
import ResponseModal from '../../shared/Modal/ResponseModal'
import ConfirmModal from '../../shared/Modal/ConfirmModal'

const ModalHandler = ({ showModal, modalType, loading, handleCloseModal, handleSubmit, error }) => (
  <Modal show={showModal} handleClose={handleCloseModal}>
    {modalType === 'url' && (
      <PageLoaderModal isLoading={loading} />
    )}
    {modalType === 'response' && (
      <ResponseModal show={showModal} handleClose={handleCloseModal} error={error} />
    )}
    {modalType === 'confirm' && (
      <ConfirmModal
        show={showModal}
        handleSubmit={handleSubmit}
        handleClose={handleCloseModal}
        message="Ya existe una factura para este mes. ¿Desea sobrescribirla?"
      />
    )}
  </Modal>
)

export default ModalHandler
