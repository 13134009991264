import React, { useCallback, useEffect, useState } from 'react'

import {
  createBills,
  getBilledPackages,
  getBills,
  savePackages,
  updatePackages
} from '../helpers/requests/billing'
import { handleBillingsDownload, parseJsonOrders } from '../helpers/OrdersBilling'
import useFetch from '../hooks/useFetch'
import { updateElementArrayOfObjects } from '../utils/array'
import ModalHandler from '../components/Billing/ModalHandler'
import ControlOptions from '../components/Billing/ControlCenter'
import SearchAndEditControls from '../components/Billing/SearchAndEditControls'
import TableSection from '../components/Billing/TableSection'
import FormButton from '../components/shared/FormButton'

const Billing = () => {
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [searchParam, setSearchParam] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [editedPackages, setEditedPackages] = useState([])
  const [editDataBackup, setEditDataBackup] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [shouldFetch, setShouldFetch] = useState(false)
  const [totalPackagesCount, setTotalPackagesCount] = useState(0)

  const initializeData = useCallback((data) => {
    const { newPackages, count } = data
    setSearchResult(newPackages)
    setTotalPackagesCount(count)
  }, [])

  const { isLoading } = useFetch(getBilledPackages, initializeData, '')

  const setModalData = (data, type) => {
    setShowModal(true)
    setModalType(type)
    setModalMessage(data)
  }

  const resetData = () => {
    setEditedPackages([])
    setIsEdited(false)
    setShouldFetch(true)
    setSearchParam('')
    setPage(0)
  }

  const saveData = async (columns, fileData, courier) => {
    setLoading(true)
    if (!fileData) {
      setLoading(false)
      return
    }
    try {
      const parsedData = parseJsonOrders(courier, fileData, columns)
      const savedPackagesResponse = await savePackages({ sales: parsedData, courier })
      setModalData(savedPackagesResponse, 'response')
      setShouldFetch(true)
    } catch (e) {
      setModalData({ status: 500, data: { body: e.message } }, 'response')
    }
    setLoading(false)
  }

  useEffect(() => {
    if (shouldFetch) {
      getBilledPackages({ page, searchParam, pageSize })
        .then(initializeData)
        .finally(() => setShouldFetch(false))
    }
  }, [shouldFetch, initializeData, page, searchParam, pageSize])

  const editPackages = async () => {
    if (isEdited) {
      const result = await updatePackages({ editedPackages })
      setModalData(result, 'response')
      resetData()
    } else {
      setIsEdited(true)
      setEditDataBackup([...searchResult])
    }
  }

  const cancelEdit = () => {
    resetData()
    setSearchResult([...editDataBackup])
  }

  const searchHandler = (term) => {
    setSearchParam(term)
  }

  const handleCheckboxChange = (item, field) => {
    const newEditedPackages = updateElementArrayOfObjects(
      editedPackages,
      { ...item, [field]: !item[field] },
      'id'
    )
    setEditedPackages(newEditedPackages)
    const updatedProcessedFile = searchResult.map((sale) => {
      if (sale.id === item.id) {
        return { ...sale, [field]: !sale[field] }
      }
      return sale
    })
    setSearchResult(updatedProcessedFile)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setModalType('')
  }

  const handleCreateBills = async () => {
    setLoading(true)
    setShowModal(true)
    setModalType('response')
    try {
      const response = await createBills()
      setModalData(response, 'response')
    } catch (e) {
      setModalData({ status: 500, data: { body: `Error al crear Facturas.\n${e}` } }, 'response')
    }
    setLoading(false)
  }
  const handleConfirmCreateBills = ()=>{
    setShowModal(true)
    setModalType('confirm')
    setModalMessage('Esta acción sobrescribirá las facturas ya creadas. ¿Desea continuar? ')
  }

  const handleGetBillData = async () => {
    setLoading(true)
    try {
      const response = await getBills('')
      const message = handleBillingsDownload(response)
      setModalData(message, 'response')
    } catch (e) {
      setModalData(
        { statusCode: 500, data: { body: `Error al traer Facturas.\n${e}` } },
        'response'
      )
    }
    setLoading(false)
  }

  return (
    <div className="relative flex h-screen flex-col bg-light-grey">
      <div className="mx-10 my-5">
        <div className="mb-8 text-left text-xl font-semibold">Facturas de Courier</div>
        <ModalHandler
          showModal={showModal}
          modalType={modalType}
          modalMessage={modalMessage}
          loading={loading}
          handleCloseModal={handleCloseModal}
          saveData={saveData}
          handleSubmit={handleCreateBills}
        />
        <div className="mb-4 flex items-end justify-between">
          <div>
            <FormButton
              isEditing={loading}
              text={!loading ? 'Subir factura' : 'Cargando...'}
              onClick={() => setModalData(null, 'upload')}
              type="button"
              styles="px-4 h-10 mt-5 mx-10 text-base"
              disabled={loading}
            />
            <ControlOptions
              saveData={saveData}
              loading={loading}
              handleConfirmCreateBills={handleConfirmCreateBills}
              handleGetBillData={handleGetBillData}
              setShowModal={setShowModal}
              setModalType={setModalType}
            />
          </div>
          <SearchAndEditControls
            searchBarInput={searchParam}
            searchHandler={searchHandler}
            setShouldFetch={setShouldFetch}
            isEdited={isEdited}
            editPackages={editPackages}
            cancelEdit={cancelEdit}
            loading={loading}
          />
        </div>
        <TableSection
          headers={[
            'Courier',
            'Company',
            'OV',
            'OT',
            'Comuna',
            'Envío',
            'Peso declarado',
            'Peso cobrado',
            'Cobrado',
            'Precio base',
            'A cobrar',
            'Aprobado por Peso',
            'Aprobado por Precio',
            'Pagado',
            'Activo'
          ]}
          isLoading={isLoading}
          currentBills={searchResult}
          handleCheckboxChange={handleCheckboxChange}
          isEdited={isEdited}
          page={page}
          setPage={(data) => {
            setPage(data)
            setShouldFetch(true)
          }}
          pageSize={pageSize}
          setPageSize={(data) => {
            setPageSize(data)
            setShouldFetch(true)
          }}
          itemsCount={totalPackagesCount}
        />
      </div>
    </div>
  )
}

export default Billing
