import React from 'react'
import Loader from '../../shared/Loader'

const PageLoaderModal = (isLoading) => (
  <div>
    {isLoading && (
      <div className="flex flex-col justify-center">
        <Loader />
        <div className="text-center">Obteniendo Link...</div>
      </div>
    )}
  </div>
)

export default PageLoaderModal
